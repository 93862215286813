import { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFragment, useMutation } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { leaveAccountModal_anonymizeMyUserMutation } from "@relay/leaveAccountModal_anonymizeMyUserMutation.graphql";
import { leaveAccountModal_authViewerSchemaFragmentRef$key } from "@relay/leaveAccountModal_authViewerSchemaFragmentRef.graphql";
import {
	AUTH_VIEWER_SCHEMA_FRAGMENT,
	LEAVE_ACCOUNT_MUTATION,
} from "@screens/account/parts/account-tab/parts/leave-account-modal/leave-account-modal.graphql";
import { logout, selectCurrentAccountId } from "@slices/AuthSlice";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { Wrapper } from "./leave-account-modal.styles";
import { type LeaveAccountModalProps } from "./leave-account-modal.types";

export const LeaveAccountModal: FC<LeaveAccountModalProps> = ({
	isVisible,
	onDismiss,
	onSuccess,
	authViewerSchemaFragmentRef,
}) => {
	const authFragment = useFragment<leaveAccountModal_authViewerSchemaFragmentRef$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);
	const currentAccountId = useSelector(selectCurrentAccountId);
	const accountName = useMemo(
		() =>
			authFragment.currentUser?.accounts.find((a) => a.id == currentAccountId)?.name ??
			"Kontoname",
		[authFragment.currentUser?.accounts, currentAccountId],
	);

	const [leave, _] =
		useMutation<leaveAccountModal_anonymizeMyUserMutation>(LEAVE_ACCOUNT_MUTATION);
	const dispatch = useDispatch();
	const handleOnSuccess = () => {
		leave({
			variables: {
				input: {
					id: currentAccountId!,
				},
			},
			onCompleted: () => {
				onSuccess?.();
				dispatch(logout());
				window.location.href = "/";
			},
		});
	};
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel="Konto verlassen"
				primaryButtonColorVersion="error"
				primaryButtonIconName="logout"
				onPrimaryButtonClick={handleOnSuccess}
			>
				<Wrapper>
					<H1Span>Konto wirklich verlassen?</H1Span>
					<TkaP2Span>
						Bevor du gehst, möchten wir dich darauf hinweisen, dass dein Zugriff auf die
						Lerninhalte und Lizenzen des Kontos verloren gehen. Bist du sicher, dass du
						das Konto '{accountName}' verlassen möchtest? Diese Aktion kann nicht von
						dir rückgängig gemacht werden
					</TkaP2Span>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
