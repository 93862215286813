import { ResidenceDropdownOptions } from "@screens/account/parts/account-tab/parts/residence-dropdown/residence-dropdown.consts";

export const getGrossPriceByCountryCode = (
	countryCode: ResidenceDropdownOptions,
	licenseProduct:
		| {
				netPrice?: number;
				deGrossPrice?: number;
				atGrossPrice?: number;
				chGrossPrice?: number;
		  }
		| undefined
		| null,
): number | undefined => {
	if (!licenseProduct) {
		return undefined;
	}

	switch (countryCode) {
		case "DE":
			return licenseProduct.deGrossPrice;
		case "AT":
			return licenseProduct.atGrossPrice;
		case "CH":
			return licenseProduct.chGrossPrice;
		default:
			return licenseProduct.deGrossPrice;
	}
};
