import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ProfileMenu } from "@components/profile-menu";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { Path } from "@router/paths";
import { selectIsAvgsClient } from "@slices/AuthSlice";

export const ProfileMenuScreen = () => {
	const navigate = useNavigate();
	const { isMediumUp } = useWindowSize();

	useEffect(() => {
		if (isMediumUp) navigate(Path.progression.path);
	}, [isMediumUp]);

	const isAvgsClient = useSelector(selectIsAvgsClient);

	return (
		<ScreenWithNavbarLayout
			canGoBack={isAvgsClient}
			headerLeftIcon="close"
			isNavbarVisible={!isAvgsClient}
		>
			<ProfileMenu />
		</ScreenWithNavbarLayout>
	);
};
