import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { NavbarItem } from "@components/navbar-item";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { navbar_AuthViewerSchemaFragment$key } from "@relay/navbar_AuthViewerSchemaFragment.graphql";
import { navbar_ClickoutMutation } from "@relay/navbar_ClickoutMutation.graphql";
import { navbar_Query } from "@relay/navbar_Query.graphql";
import { Path } from "@router/paths";
import { shade0, shade100 } from "@themes/colors";
import { TkaL1Span } from "@themes/font-tags";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, CLICKOUT_MUTATION, QUERY } from "./navbar.graphql";
import { NavbarSkeleton } from "./navbar.skeleton";
import { CenterWrapper, HideXLUp, ShowXLUp, StyledLogo, Wrapper } from "./navbar.styles";
import { AvatarWithProfileMenu } from "../avatar-with-profile-menu";
import { Icon } from "../icon";
import { Wrapper as NavbarItemWrapper } from "../navbar-item/navbar-item.styles";
import { withSuspense } from "../with-suspense/with-suspense.component";

// TODO: add-translations
export const NavbarComponent = () => {
	const query = useLazyLoadQuery<navbar_Query>(QUERY, {}, { fetchPolicy: "store-or-network" });
	const [createClickOutForUserOrBusiness] =
		useMutation<navbar_ClickoutMutation>(CLICKOUT_MUTATION);

	const auth = useFragment<navbar_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		query.Viewer.Auth,
	);

	const { isXLargeUp } = useWindowSize();
	const { isBusinessAccount, hasPlacementPermission, isPlacementContactPerson, isAccountOwner } =
		usePermissions();

	const navigate = useNavigate();

	const hideTalentJobMatching = isBusinessAccount && !isPlacementContactPerson && !isAccountOwner;

	const placementEnabled = process.env.REACT_APP_PLACEMENT_ENABLED === "true" || false;

	// temporarily disable checking for potential analysis
	// const hasDonePotentialAnalysisMAX =
	// 	query?.Viewer?.PotentialAnalysis?.MyPotentialAnalysisResult?.isLongAnalysis;

	const navigateToPlacementScreen =
		!placementEnabled ||
		// (!isBusinessAccount && !hasDonePotentialAnalysisMAX) ||
		(isBusinessAccount && !hasPlacementPermission);

	const handleOnClick = () => {
		createClickOutForUserOrBusiness({
			variables: {
				input: {},
			},
			onCompleted: (response) => {
				window.location.href =
					response.Placement.createClickOutForUserOrBusiness?.placementUrl || "/";
				window.history.pushState({}, "", "/progression");
			},
		});
	};

	const handleLogoOnClick = () => {
		navigate(Path.dashboard.path);
	};

	const TalentJobMatchingItem = () => {
		if (hideTalentJobMatching) return null;
		if (navigateToPlacementScreen) {
			return (
				<NavbarItem
					iconName="locationPin"
					label="Talent Job Matching"
					path={Path.placement.path}
				/>
			);
		} else
			return (
				<NavbarItemWrapper onClick={handleOnClick}>
					<Icon
						icon={"locationPin"}
						sizeInRem={1}
						tkaColor={isXLargeUp ? shade0 : shade100}
					/>
					<TkaL1Span tkaColor={isXLargeUp ? shade0 : shade100} bold>
						Talent Job Matching
					</TkaL1Span>
				</NavbarItemWrapper>
			);
	};

	return (
		<Wrapper>
			<StyledLogo onClick={handleLogoOnClick} />
			<CenterWrapper>
				<NavbarItem iconName="rewardStars" label="Zukunftstrends" path={Path.trends.path} />
				<NavbarItem iconName="flowers" label="Orientierung" path={Path.orientation.path} />
				<NavbarItem
					iconName="book"
					label="Weiterentwicklung"
					path={Path.progression.path}
				/>
				{TalentJobMatchingItem()}
				<HideXLUp>
					<AvatarWithProfileMenu authViewerSchemaFragmentRef={auth} />
				</HideXLUp>
			</CenterWrapper>
			<ShowXLUp>
				<AvatarWithProfileMenu authViewerSchemaFragmentRef={auth} />
			</ShowXLUp>
		</Wrapper>
	);
};

export const Navbar = withSuspense(NavbarComponent, NavbarSkeleton);
