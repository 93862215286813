import { CenterWrapper, HideXLUp, ShowXLUp, StyledLogo, Wrapper } from "./navbar.styles";
import { NavbarItemSkeleton } from "../navbar-item/navbar-item.skeleton";
import { Skeleton } from "../skeleton/skeleton.component";

// TODO: remove-translations
export const NavbarSkeleton = () => {
	return (
		<Wrapper>
			<StyledLogo />
			<CenterWrapper>
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				<HideXLUp>
					<Skeleton shape="circle" size="2.5rem" />
				</HideXLUp>
			</CenterWrapper>
			<ShowXLUp>
				<Skeleton shape="circle" size="2.5rem" />
			</ShowXLUp>
		</Wrapper>
	);
};
