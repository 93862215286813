/**
 * @generated SignedSource<<913046b288241db9d6c82a976a0cb7c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type CustomerType = "Business" | "Private";
export type SalutationType = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartInvoice_OrderFragment$data = {
  readonly billingDetails: {
    readonly city: string;
    readonly company?: string;
    readonly companyDetails?: string | null;
    readonly companyType?: CompanyType;
    readonly country: string;
    readonly customerType: CustomerType;
    readonly dateOfBirth?: string;
    readonly firstName?: string;
    readonly houseNumber: string;
    readonly invoiceEmail?: string;
    readonly lastName?: string;
    readonly phoneNumber: string;
    readonly postalCode: string;
    readonly salutation?: SalutationType;
    readonly street: string;
    readonly title?: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartInvoice_OrderFragment";
};
export type shoppingCartInvoice_OrderFragment$key = {
  readonly " $data"?: shoppingCartInvoice_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartInvoice_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartInvoice_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "billingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "houseNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salutation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateOfBirth",
              "storageKey": null
            }
          ],
          "type": "PrivateBillingDetails",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyDetails",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceEmail",
              "storageKey": null
            }
          ],
          "type": "BusinessBillingDetails",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "3c032c2a2016b2d25ce4c55ac9057d94";

export default node;
