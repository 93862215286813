import { error100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { Wrapper } from "./license-card.styles";
import { Icon } from "../icon";

// TODO: add-translations
export const LicenseCard = () => {
	return (
		<Wrapper>
			<Icon icon="removeBadge" sizeInRem={1.5} tkaColor={error100} />
			<TkaP2Span tkaColor={error100}>
				Um diesen Kurs abschließen zu können, wird eine&nbsp;
				<TkaP2Span tkaColor={error100} bold>
					Lizenz benötigt.
				</TkaP2Span>
			</TkaP2Span>
		</Wrapper>
	);
};
