/**
 * @generated SignedSource<<66d38f4f08404aa914d788f876871875>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrderInput = {
  cartSelection?: CartSelectionInput | null;
  clientMutationId?: string | null;
};
export type CartSelectionInput = {
  createdByUser?: string | null;
  selectedDiscountCodes: ReadonlyArray<string>;
  selectedProducts: ReadonlyArray<SelectedProductInput>;
};
export type SelectedProductInput = {
  additionalInformation?: string | null;
  amount: number;
  productId: string;
};
export type createOrder_CreateOrderMutation$variables = {
  input: CreateOrderInput;
};
export type createOrder_CreateOrderMutation$data = {
  readonly Billing: {
    readonly createOrder: {
      readonly order: {
        readonly cart: {
          readonly items: ReadonlyArray<{
            readonly product?: {
              readonly id: string;
              readonly title: string;
            } | null;
          }>;
          readonly totals: {
            readonly includingAllDiscounts: {
              readonly grossPrice: number;
              readonly netPrice: number;
            };
          };
        } | null;
        readonly id: string;
      };
    } | null;
  };
};
export type createOrder_CreateOrderMutation = {
  response: createOrder_CreateOrderMutation$data;
  variables: createOrder_CreateOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CartTotals",
  "kind": "LinkedField",
  "name": "totals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "includingAllDiscounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createOrder_CreateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateOrderPayload",
            "kind": "LinkedField",
            "name": "createOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "cart",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "CartProduct",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createOrder_CreateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateOrderPayload",
            "kind": "LinkedField",
            "name": "createOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "cart",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "CartProduct",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6859d1fe32ffe4e7f4f16fda1cd9c1ed",
    "id": null,
    "metadata": {},
    "name": "createOrder_CreateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation createOrder_CreateOrderMutation(\n  $input: CreateOrderInput!\n) {\n  Billing {\n    createOrder(input: $input) {\n      order {\n        id\n        cart {\n          items {\n            __typename\n            ... on CartProduct {\n              product {\n                __typename\n                id\n                title\n              }\n            }\n          }\n          totals {\n            includingAllDiscounts {\n              grossPrice\n              netPrice\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "edf15a227052c2cd86ddcebe5e982b68";

export default node;
