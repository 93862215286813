/**
 * @generated SignedSource<<9c49a6a123aefd5e5bbd1af09466fb1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeaveAccountInput = {
  clientMutationId?: string | null;
  id: string;
};
export type leaveAccountModal_anonymizeMyUserMutation$variables = {
  input: LeaveAccountInput;
};
export type leaveAccountModal_anonymizeMyUserMutation$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly leaveAccount: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type leaveAccountModal_anonymizeMyUserMutation = {
  response: leaveAccountModal_anonymizeMyUserMutation$data;
  variables: leaveAccountModal_anonymizeMyUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerMutationType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthViewerMutationSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "LeaveAccountPayload",
            "kind": "LinkedField",
            "name": "leaveAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leaveAccountModal_anonymizeMyUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leaveAccountModal_anonymizeMyUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ef112f42d17784aaf1a15abdc3df124b",
    "id": null,
    "metadata": {},
    "name": "leaveAccountModal_anonymizeMyUserMutation",
    "operationKind": "mutation",
    "text": "mutation leaveAccountModal_anonymizeMyUserMutation(\n  $input: LeaveAccountInput!\n) {\n  Viewer {\n    Auth {\n      leaveAccount(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41b93645064d7a5b2171a8b715fda7ce";

export default node;
