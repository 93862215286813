import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { COACHING_OFFER_FRAGMENT } from "@components/coaching-offer-card/coaching-offer-card.graphql";
import { CoachingOfferCardProps } from "@components/coaching-offer-card/coaching-offer-card.types";
import { OfferCard } from "@components/offer-card";
import { coachingOfferCard_CoachingOfferFragment$key } from "@relay/coachingOfferCard_CoachingOfferFragment.graphql";
import { Path } from "@router/paths";
import { stripHtml } from "@utils/strip-html";

export const CoachingOfferCard = ({
	onClick,
	coachingOfferFragmentRef,
}: CoachingOfferCardProps) => {
	const offer = useFragment<coachingOfferCard_CoachingOfferFragment$key>(
		COACHING_OFFER_FRAGMENT,
		coachingOfferFragmentRef,
	);

	const navigate = useNavigate();
	const handleOnClick = () => {
		if (onClick) return onClick();
		if (!offer?.id) return;
		navigate(Path.coaching.withId(offer.id).path);
	};
	const shortDescription = useMemo(
		() => stripHtml(offer.description?.shortDescription ?? ""),
		[offer.description?.shortDescription],
	);
	const tags = useMemo(
		() => offer.description?.tags?.map((t) => t.data.name),
		[offer.description?.tags],
	);

	return (
		<OfferCard
			src={offer.description?.image?.url ?? ""}
			licenseProductFragmentRef={offer.description?.licenseProduct}
			title={offer.description?.name ?? ""}
			shortDescription={shortDescription}
			tags={tags}
			availableLicenseCount={offer.licenseAvailability.numAvailable ?? 0}
			onClick={handleOnClick}
		/>
	);
};
