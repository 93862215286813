/**
 * @generated SignedSource<<68fddd9db8ec4edccf37755c786f749c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type offerCard_LicenseProductFragment$data = {
  readonly atGrossPrice: number;
  readonly chGrossPrice: number;
  readonly deGrossPrice: number;
  readonly isTaxFree: boolean;
  readonly netPrice: number;
  readonly " $fragmentType": "offerCard_LicenseProductFragment";
};
export type offerCard_LicenseProductFragment$key = {
  readonly " $data"?: offerCard_LicenseProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offerCard_LicenseProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offerCard_LicenseProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deGrossPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "atGrossPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chGrossPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTaxFree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    }
  ],
  "type": "LicenseProduct",
  "abstractKey": null
};

(node as any).hash = "1d28db95c85d24cbfd0b4f303d772edb";

export default node;
