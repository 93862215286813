import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query shoppingCartSuccess_Query($orderId: ID!, $skip: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartSuccess_OrderFragment
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						email
					}
				}
			}
		}
	}
`;

export const SUCCESS_SCREEN_ORDER_FRAGMENT = graphql`
	fragment shoppingCartSuccess_OrderFragment on Order {
		id
		cart {
			items {
				itemType
				... on CartLimitedDiscount {
					title
					value {
						kind
						... on DiscountActionPercentageCalcType {
							percentage
						}
						... on DiscountActionEuroCalcType {
							euro
						}
					}
				}
				... on CartDiscount {
					validUntil
					code
					title
					value {
						kind
						... on DiscountActionPercentageCalcType {
							percentage
						}
						... on DiscountActionEuroCalcType {
							euro
						}
					}
				}
				... on CartGlobalDiscount {
					title
					value {
						kind
						... on DiscountActionPercentageCalcType {
							percentage
						}
						... on DiscountActionEuroCalcType {
							euro
						}
					}
				}
				... on CartProduct {
					amount
					product {
						id
						title
						price {
							grossPrice
						}
						... on LicenseProduct {
							licenseDefinitionAmount
							licenseDefinition {
								data {
									kind
									... on TreeLicenseDefinitionData {
										rootInfo {
											image {
												url
											}
											contractPartner {
												data {
													name
													linkToAGB
												}
											}
										}
									}
									... on CoachingLicenseDefinitionData {
										coachingInfo {
											image {
												url
											}
											contractPartner {
												data {
													name
													linkToAGB
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			totals {
				withoutDiscounts {
					grossPrice
				}
				includingAllDiscounts {
					grossPrice
					netPrice
				}
				monthlyOptions {
					downPayment {
						grossPrice
						netPrice
						taxRatePercentage
					}
					financedAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					monthlyRate {
						grossPrice
						netPrice
						taxRatePercentage
					}
					rateCount
					totalAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					closingRate {
						grossPrice
						netPrice
						taxRatePercentage
					}
					totalAmount {
						grossPrice
					}
				}
			}
		}
		selectedPaymentMethod {
			paymentMethodId
			selectedPaymentMethodType
			... on OneTimeSelectedPaymentMethod {
				stripeData {
					paymentIntentSecret
				}
			}
			... on MonthlySelectedPaymentMethod {
				chosenOption {
					downPayment {
						grossPrice
						netPrice
						taxRatePercentage
					}
					financedAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					monthlyRate {
						grossPrice
						netPrice
						taxRatePercentage
					}
					rateCount
					totalAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					closingRate {
						grossPrice
						netPrice
						taxRatePercentage
					}
					totalAmount {
						grossPrice
					}
					paymentMethodTypes
				}
			}
		}
		allowedPaymentMethods
		...shoppingCartLegalForm_OrderFragment
		...shoppingCartTemplate_OrderFragment
	}
`;
