import { useFormik } from "formik";
import { isValid } from "iban";
import { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import { BusinessTypeDropdown } from "@components/business-type-dropdown";
import { Divider } from "@components/divider";
import { ValidatedInputMask } from "@components/validated-input-mask";
import { ValidatedInputText } from "@components/validated-input-text";
import { TkaH2Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./business-account-form.graphql";
import {
	ColSpan2,
	ColSpan4,
	InputGroupWrapper,
	ShortInputsRow,
	Wrapper,
} from "./business-account-form.styles";
import {
	BusinessAccountFormProps,
	BusinessAccountFormRef,
	BusinessAccountFormState,
} from "./business-account-form.types";
import { ResidenceDropdown } from "../residence-dropdown";

//TODO: add-translations
export const BusinessAccountForm = forwardRef<BusinessAccountFormRef, BusinessAccountFormProps>(
	function BusinessAccountForm({ baseDataFragmentRef, onSubmit }, ref) {
		const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef ?? null);

		const form = useFormik<BusinessAccountFormState>({
			initialValues: {
				companyName: query?.companyName ?? "",
				billingOffice: query?.billingOffice ?? "",
				companyLegalForm: query?.companyLegalForm ?? undefined,
				invoiceEmail: query?.invoiceEmail ?? "",
				street: query?.street ?? "",
				houseNumber: query?.houseNumber ?? "",
				postalCode: query?.postalCode ?? "",
				city: query?.city ?? "",
				countryCode: query?.countryCode ?? "DE",
				phoneNumber: query?.phoneNumber ?? "",
				taxNumber: query?.taxData?.taxNumber ?? "",
				taxIdentificationNumber: query?.taxData?.taxIdentificationNumber ?? "",
				iban: query?.accountData?.iban ?? "",
				bic: query?.accountData?.bic ?? "",
			},
			validateOnChange: false,
			validateOnBlur: false,
			validationSchema: Yup.object().shape({
				companyName: Yup.string().required("Bitte gib den Name des Unternehmens ein."),
				billingOffice: Yup.string().required("Bitt gib eine Rechnungsstelle ein."),
				companyLegalForm: Yup.string().required("Bitte wähle eine Rechtsform aus."),
				invoiceEmail: Yup.string()
					.email("Bitte gib eine gültige E-Mail Adresse ein.")
					.required("Bitte gib eine E-Mail Adresse ein."),
				street: Yup.string().required("Bitte gib eine Straße ein."),
				houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
				city: Yup.string().required("Bitte gib einen Ort ein."),
				postalCode: Yup.string()
					.when("countryCode", {
						is: (value: string) => value === "CH" || value === "AT",
						then: Yup.string()
							.length(4, "Bitte gib eine gültige Postleitzahl ein")
							.matches(/^[0-9]{4}/, "Bitte gib eine gültige Postleitzahl ein"),
					})
					.when("countryCode", {
						is: "DE",
						then: Yup.string()
							.length(5, "Bitte gib eine gültige Postleitzahl ein")
							.matches(/^[0-9]{5}/, "Bitte gib eine gültige Postleitzahl ein"),
					})
					.required("Bitte gib eine Postleitzahl ein."),
				phoneNumber: Yup.string().required("Bitte gib eine Telefonnummer ein."),
				countryCode: Yup.string().required("Bitte wähle ein Land aus."),
				iban: Yup.string()
					.test("test-card-number", "Bitte gib eine gültige IBAN ein.", (value) =>
						isValid(value ?? ""),
					)
					.required("Bitte gib eine IBAN ein."),
				bic: Yup.string().when("countryCode", {
					is: "CH",
					then: Yup.string().required("Bitte gib einen BIC ein."),
				}),
				taxNumber: Yup.string().required("Bitte gib eine Steuernummer ein."),
				taxIdentificationNumber: Yup.string().required(
					"Bitte gib eine Umsatzsteuer-Identifikationsnummer ein.",
				),
			}),
			onSubmit: (values) => {
				onSubmit?.(values);
			},
		});

		useImperativeHandle(ref, () => ({
			submit: form.submitForm,
			reset: form.resetForm,
			validate: () => form.validateForm().then((errors) => !errors),
		}));

		const isSwitzerland = form.values.countryCode === "CH";

		return (
			<Wrapper>
				<TkaH2Span>Unternehmen</TkaH2Span>
				<InputGroupWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="companyName"
						label="Name des Unternehmens (inkl. Geschäftsform)*"
						placeholder="z.B. Muster Unicorn GmbH ..."
					/>
					<ValidatedInputText
						formikConfig={form}
						name="billingOffice"
						label="Rechnungsstelle oder Empfänger*"
						placeholder="z.B. IT-Department ..."
					/>
					<BusinessTypeDropdown
						formikConfig={form}
						name="companyLegalForm"
						label="Rechtsform*"
						placeholder="z.B. GmbH, GbR, OHG ..."
					/>
					<ValidatedInputText
						formikConfig={form}
						name="invoiceEmail"
						label="E-Mail für Rechnung*"
						placeholder="..."
					/>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedInputText
								formikConfig={form}
								name="street"
								label="Straße*"
								placeholder="..."
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedInputText
								formikConfig={form}
								name="houseNumber"
								label="Hausnummer*"
								placeholder="..."
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedInputText
								formikConfig={form}
								name="city"
								label="Ort/Stadt*"
								placeholder="..."
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedInputText
								formikConfig={form}
								name="postalCode"
								label="PLZ*"
								placeholder="..."
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ValidatedInputText
						formikConfig={form}
						name="phoneNumber"
						label="Telefonnummer *"
						placeholder="z.B. +49 174 00 00 000 ..."
					/>
					<ResidenceDropdown
						formikConfig={form}
						name="countryCode"
						label="Steuerpflichtiges Land"
					/>
				</InputGroupWrapper>
				<Divider />
				<TkaH2Span>Steuerdaten</TkaH2Span>
				<InputGroupWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="taxNumber"
						label={
							isSwitzerland
								? "Mehrwertsteuernummer (MWST-Nummer) *"
								: "Steuernummer (St.-Nr.)*"
						}
						placeholder={
							isSwitzerland ? "CHE-123456789MWST ..." : "z.B. 12/345/67890 ..."
						}
					/>
					<ValidatedInputMask
						formikConfig={form}
						name="taxIdentificationNumber"
						label={
							isSwitzerland
								? "Unternehmens-Identifikationsnummer (UID-Nummer) *"
								: "Umsatzsteuer-Identifikationsnummer (USt-IdNr.)*"
						}
						placeholder={isSwitzerland ? "CHE-123456789 ..." : "z.B. DE123456789 ..."}
						mask={isSwitzerland ? "CHE-999999999" : "DE999999999?999"}
					/>
				</InputGroupWrapper>
				<Divider />
				<TkaH2Span>Kontodaten</TkaH2Span>
				<InputGroupWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="iban"
						label="IBAN*"
						placeholder={
							isSwitzerland
								? "z.B. CH93 0076 2011 6238 5295 7 ..."
								: "z.B. DE89 3704 0044 0532 0130 00 ..."
						}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="bic"
						label={`BIC/SWIFT-Code${isSwitzerland ? "*" : ""}`}
						placeholder={isSwitzerland ? "z.B. UBSWCHZH80A ..." : "DEUTDEFFXXX ..."}
					/>
				</InputGroupWrapper>
			</Wrapper>
		);
	},
);
