import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginEmailPassword } from "@components/login-email-password";
import { LoginEmailPasswordFormState } from "@components/login-email-password/login-email-password.interface";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { useAuthContext } from "@hooks/use-auth-context";
import { useToast } from "@hooks/useToast";
import { loginSteps_CreateClickoutByLinkIdMutation } from "@relay/loginSteps_CreateClickoutByLinkIdMutation.graphql";
import { loginSteps_InvitationByTokenQuery } from "@relay/loginSteps_InvitationByTokenQuery.graphql";
import { Path } from "@router/paths";
import { PlacementContext } from "@screens/placement/placement.context";
import { CREATE_CLICKOUT_BY_LINKID_MUTATION, INVITATION_QUERY } from "./login-steps.graphql";
import { LoginStepsProps } from "./login-steps.types";
import { AuthTemplateStep } from "../auth-template-step";

export const LoginSteps = ({ inline, onGoToSignUp, onGoToForgotPassword }: LoginStepsProps) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const linkId = searchParams.get("linkId");
	const { state } = location;

	const [createClickoutByLinkId] = useMutation<loginSteps_CreateClickoutByLinkIdMutation>(
		CREATE_CLICKOUT_BY_LINKID_MUTATION,
	);

	const { placementLinkId, setPlacementLinkId } = useContext(PlacementContext);
	const navigate = useNavigate();
	const { showError } = useToast();
	const { t } = useTranslation("inviteScreens");

	const {
		Auth: { InvitationByToken: invitation },
	} = useLazyLoadQuery<loginSteps_InvitationByTokenQuery>(INVITATION_QUERY, {
		token: state?.invitationToken ?? "",
		skip: !state?.invitationToken,
	});

	useEffect(() => {
		if (linkId) {
			setPlacementLinkId(linkId);
		}
	}, [linkId]);

	const { login } = useAuthContext();

	const handlePlacementRedirect = (email: string, password: string) => {
		createClickoutByLinkId({
			variables: {
				input: {
					linkId: placementLinkId,
				},
			},
			onCompleted: (response) => {
				const clickoutURL = response?.Placement.createClickOutByLinkId?.placementUrl;
				if (!clickoutURL) {
					navigate(Path.progression.path, { replace: true });
					showError({
						summary: "Das hat leider nicht geklappt",
						detail: "Weiterleitung fehlgeschlagen",
					});
				}
				window.location.href = clickoutURL!;
			},
			onError: () => {
				login(email, password).then((response) => {
					const redirectUrl =
						response.Auth.loginJwt?.loginResult.extension.forwardToFrontendURL;
					if (redirectUrl) {
						window.location.href = redirectUrl;
						return;
					}
					navigate(state?.redirect ?? Path.dashboard.path, { replace: true });
				});
				showError({
					summary: "Das hat leider nicht geklappt",
					detail: "Weiterleitung fehlgeschlagen",
				});
			},
		});
	};

	const handleLoginOnSubmit = (values: LoginEmailPasswordFormState) => {
		login(values.email, values.password).then((response) => {
			if (inline) {
				return;
			}
			if (placementLinkId) {
				handlePlacementRedirect(values.email, values.password);
				return;
			}
			const redirectUrl = response.Auth.loginJwt?.loginResult.extension.forwardToFrontendURL;
			if (redirectUrl) {
				window.location.href = redirectUrl;
				return;
			}
			navigate(state?.redirect ?? Path.progression.path, { replace: true });
		});
	};

	const handleGoToSignUp = () => {
		if (onGoToSignUp) return onGoToSignUp();
		navigate(Path.signup.path, { state });
	};

	const handleGoToForgotPassword = () => {
		if (onGoToForgotPassword) return onGoToForgotPassword();
		navigate(Path.forgotPassword.path);
	};

	const inviteText =
		invitation?.accountName && invitation?.invitingUserName
			? t("invite_screens.invitedByTitle", {
					account: invitation.accountName,
					invitingUser: invitation.invitingUserName,
			  })
			: undefined;

	const step = (
		<LoginEmailPassword
			headerText={inviteText}
			onSubmit={handleLoginOnSubmit}
			onGoToSignUp={handleGoToSignUp}
			onGoToForgotPassword={handleGoToForgotPassword}
		/>
	);

	return inline ? (
		step
	) : (
		<MultiStepAuthContainer StepWrapper={AuthTemplateStep}>{() => step}</MultiStepAuthContainer>
	);
};
