/**
 * @generated SignedSource<<79a50e3f224326591074d2f897abceac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DiscountActionCalcTypeKind = "euro" | "percentage";
export type ItemType = "CartBulkDiscount" | "CartDiscount" | "CartGlobalDiscount" | "CartLimitedDiscount" | "CartProduct";
export type LicenseDefinitionDataKind = "AVGSPotentialAnalysis" | "Coaching" | "MicroLearning" | "Tree";
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type SelectedPaymentMethodType = "EmployerInvoice" | "MonthlyPayment" | "OneTimePayment";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartOverview_OrderFragment$data = {
  readonly allowedPaymentMethods: ReadonlyArray<PaymentMethodType>;
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly amount?: number;
      readonly code?: string;
      readonly itemType: ItemType;
      readonly product?: {
        readonly id: string;
        readonly licenseDefinition?: {
          readonly data: {
            readonly coachingInfo?: {
              readonly contractPartner: {
                readonly data: {
                  readonly linkToAGB: string | null;
                  readonly name: string;
                };
              } | null;
              readonly image: {
                readonly url: string | null;
              } | null;
            } | null;
            readonly kind: LicenseDefinitionDataKind;
            readonly rootInfo?: {
              readonly contractPartner: {
                readonly data: {
                  readonly linkToAGB: string | null;
                  readonly name: string;
                };
              } | null;
              readonly image: {
                readonly url: string | null;
              } | null;
            } | null;
          };
        } | null;
        readonly licenseDefinitionAmount?: number | null;
        readonly price: {
          readonly grossPrice: number;
        };
        readonly title: string;
      } | null;
      readonly title?: string;
      readonly validUntil?: string | null;
      readonly value?: {
        readonly euro?: number;
        readonly kind: DiscountActionCalcTypeKind;
        readonly percentage?: number;
      };
    }>;
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
        readonly netPrice: number;
      };
      readonly monthlyOptions: ReadonlyArray<{
        readonly closingRate: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly downPayment: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly financedAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly monthlyRate: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly rateCount: number;
        readonly totalAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
      }>;
      readonly withoutDiscounts: {
        readonly grossPrice: number;
      };
    };
  } | null;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly chosenOption?: {
      readonly closingRate: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly downPayment: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly financedAmount: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly monthlyRate: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly paymentMethodTypes: ReadonlyArray<PaymentMethodType>;
      readonly rateCount: number;
      readonly totalAmount: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
    };
    readonly paymentMethodId: PaymentMethodType;
    readonly selectedPaymentMethodType: SelectedPaymentMethodType;
    readonly stripeData?: {
      readonly paymentIntentSecret: string;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartLegalForm_OrderFragment" | "shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartOverview_OrderFragment";
};
export type shoppingCartOverview_OrderFragment$key = {
  readonly " $data"?: shoppingCartOverview_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartOverview_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "value",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        }
      ],
      "type": "DiscountActionPercentageCalcType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "euro",
          "storageKey": null
        }
      ],
      "type": "DiscountActionEuroCalcType",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "File",
    "kind": "LinkedField",
    "name": "image",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ContractPartner",
    "kind": "LinkedField",
    "name": "contractPartner",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractPartnerData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkToAGB",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v9 = [
  (v5/*: any*/),
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taxRatePercentage",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "downPayment",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "financedAmount",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "monthlyRate",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rateCount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "totalAmount",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "closingRate",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartOverview_OrderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemType",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "CartLimitedDiscount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validUntil",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "type": "CartDiscount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "CartGlobalDiscount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Price",
                      "kind": "LinkedField",
                      "name": "price",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "licenseDefinitionAmount",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LicenseDefinition",
                          "kind": "LinkedField",
                          "name": "licenseDefinition",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "data",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "LicenseDefinitionRootInfo",
                                      "kind": "LinkedField",
                                      "name": "rootInfo",
                                      "plural": false,
                                      "selections": (v7/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "TreeLicenseDefinitionData",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "LicenseDefinitionCoachingInfo",
                                      "kind": "LinkedField",
                                      "name": "coachingInfo",
                                      "plural": false,
                                      "selections": (v7/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "CoachingLicenseDefinitionData",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LicenseProduct",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "CartProduct",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "withoutDiscounts",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": [
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StripeData",
              "kind": "LinkedField",
              "name": "stripeData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentIntentSecret",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OneTimeSelectedPaymentMethod",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentMethodTypes",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowedPaymentMethods",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartLegalForm_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "0cf0145719511652665e00d992806234";

export default node;
