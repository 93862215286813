import { coachingDetails_UserQuery } from "@relay/coachingDetails_UserQuery.graphql";
import {
	EpicH1Span,
	EpicH2Span,
	EpicL1Span,
	EpicP1Span,
	PathParams,
} from "@thekeytechnology/epic-ui";
import { useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { Button } from "@components/button";
import { CoachCard } from "@components/coach-card";
import { FocusContentMenu } from "@components/focus-content-menu";
import { OfferPreview } from "@components/offer-preview";
import { ScreenWithNavbarSidemenuLayout } from "@layouts/screen-with-navbar-sidemenu-layout";
import { coachingDetails_GetCoachingQuery } from "@relay/coachingDetails_GetCoachingQuery.graphql";
import { CoachingPath, Path } from "@router/index";
import { ResidenceDropdownOptions } from "@screens/account/parts/account-tab/parts/residence-dropdown/residence-dropdown.consts";
import { formatCurrency } from "@utils/currency";
import { parseHtml } from "@utils/parse-html";
import { CoachesSectionId, ContentContainerId, ContentSectionId } from "./coaching-details.const";
import { QUERY, USER_QUERY } from "./coaching-details.graphql";

import {
	BottomTextWrapper,
	CoachCardsWrapper,
	CoachesWrapper,
	DescriptionButtonWrapper,
	DescriptionWrapper,
	SideMenuPadding,
	SideMenuWrapper,
	TitleDescriptionWrapper,
	Wrapper,
} from "./coaching-details.styles";
import { CoachingDetailsScreenProps } from "./coaching-details.types";

// TODO: add-translations
export const CoachingDetailsScreenWithCoachingId = ({ coachingId }: CoachingDetailsScreenProps) => {
	const query = useLazyLoadQuery<coachingDetails_GetCoachingQuery>(
		QUERY,
		{ id: coachingId },
		{ fetchPolicy: "network-only" },
	);
	const user = useLazyLoadQuery<coachingDetails_UserQuery>(USER_QUERY, {});
	const userCountryCode: ResidenceDropdownOptions =
		user.AccountBaseData.AccountBaseData.countryCode;

	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const coaching = query.node;
	const title = coaching?.description?.name ?? "";

	const focusItems = useMemo(
		() => [
			{ id: ContentSectionId, label: "Inhalt" },
			{ id: CoachesSectionId, label: "Coaches" },
		],
		[],
	);

	const netPrice = query.node?.cartClickout?.netPrice;

	const availableLicensesCount = query.node?.licenseAvailability?.numAvailable ?? 0;
	const hasLicenses = availableLicensesCount > 0;
	const availableLicensesText = `${availableLicensesCount} x Lizenz${
		availableLicensesCount > 1 ? "en" : ""
	} verfügbar`;

	const actionButtonText = hasLicenses ? "Coaching buchen" : "Lizenz erwerben";
	const actionButtonIcon = hasLicenses ? "calendar" : "shoppingBasket";

	const gotToShoppingCart = () => {
		const link = query.node?.cartClickout?.relativeLink;
		link && navigate(link);
	};

	const handleActionButtonOnClick = () => {
		if (hasLicenses) {
			navigate({
				pathname: Path.bookAppointment.withId(coachingId).path,
				search: searchParams.toString(),
			});
		} else {
			gotToShoppingCart();
		}
	};

	const handleBuyCoachingOnClick = () => {
		gotToShoppingCart();
	};

	return (
		<ScreenWithNavbarSidemenuLayout
			noSideMenuPadding
			sideMenu={
				<SideMenuWrapper>
					<SideMenuPadding>
						<OfferPreview title={title} imageUrl={coaching?.description?.image?.url} />
					</SideMenuPadding>
					<FocusContentMenu containerId={ContentContainerId} items={focusItems} />
				</SideMenuWrapper>
			}
			bottomContent={
				<BottomNavigationTemplate
					canGoBack
					actionButtonLabel={actionButtonText}
					actionButtonIconName={actionButtonIcon}
					bottomText={hasLicenses ? availableLicensesText : undefined}
					bottomNode={
						!hasLicenses ? (
							<BottomTextWrapper>
								<EpicL1Span>
									<EpicL1Span>{formatCurrency(netPrice)}</EpicL1Span>
									&nbsp;zzgl.&nbsp;
									{userCountryCode === "AT" ? "USt." : "MwSt."}
								</EpicL1Span>
							</BottomTextWrapper>
						) : undefined
					}
					onActionButtonClick={handleActionButtonOnClick}
				/>
			}
			contentContainerId={ContentContainerId}
		>
			<Wrapper>
				<TitleDescriptionWrapper>
					<EpicH1Span>{title}</EpicH1Span>
					<DescriptionWrapper id={ContentSectionId}>
						<EpicH2Span>Was dich erwartet</EpicH2Span>
						<DescriptionButtonWrapper>
							<EpicP1Span>
								{coaching?.description?.description &&
									parseHtml(coaching?.description?.description)}
							</EpicP1Span>
						</DescriptionButtonWrapper>
					</DescriptionWrapper>
					{coaching?.description?.contents && (
						<DescriptionWrapper>
							<EpicH2Span>Inhalte</EpicH2Span>
							<DescriptionButtonWrapper>
								<EpicP1Span>
									{coaching?.description?.contents &&
										parseHtml(coaching?.description?.contents)}
								</EpicP1Span>
							</DescriptionButtonWrapper>
						</DescriptionWrapper>
					)}
					<DescriptionWrapper>
						{coaching?.description?.modules && <EpicH2Span>Module</EpicH2Span>}
						<DescriptionButtonWrapper>
							{coaching?.description?.modules && (
								<EpicP1Span>
									{coaching?.description?.modules &&
										parseHtml(coaching?.description?.modules)}
								</EpicP1Span>
							)}
							{!hasLicenses && (
								<Button
									label="Lizenz erwerben"
									colorVersion="outline"
									iconName="shoppingBasket"
									onClick={handleBuyCoachingOnClick}
								/>
							)}
						</DescriptionButtonWrapper>
					</DescriptionWrapper>
				</TitleDescriptionWrapper>
				<CoachesWrapper>
					<EpicH2Span>Coaches</EpicH2Span>
					<CoachCardsWrapper>
						{coaching?.associatedCoaches?.map((coach) => (
							<CoachCard key={coach.id} userFragmentRef={coach} />
						))}
					</CoachCardsWrapper>
				</CoachesWrapper>
			</Wrapper>
		</ScreenWithNavbarSidemenuLayout>
	);
};

export const CoachingDetailsScreen = () => {
	const { coachingId } = useParams<PathParams<typeof CoachingPath>>();
	return coachingId ? <CoachingDetailsScreenWithCoachingId coachingId={coachingId} /> : null;
};
