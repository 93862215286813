import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
	items-center
`;

export const Connector = tw.div`
	w-[2rem]
	h-[0.0625px]
	bg-error-100
`;

export const Card = tw.div`
	flex-1
	flex
	flex-col
	items-center
	text-center
	px-16
	py-20
	gap-8
	rounded-12
	bg-error-20
`;
