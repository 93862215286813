/**
 * @generated SignedSource<<fbc706369363897dadb84aaabe88627a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CoachingSettingKind = "Group" | "OneToOne";
import { FragmentRefs } from "relay-runtime";
export type coachingOfferCard_CoachingOfferFragment$data = {
  readonly associatedCoaches: ReadonlyArray<{
    readonly coach: {
      readonly name: string;
    } | null;
  }>;
  readonly description: {
    readonly description: string | null;
    readonly image: {
      readonly thumbnail: string | null;
      readonly url: string | null;
    } | null;
    readonly licenseProduct: {
      readonly " $fragmentSpreads": FragmentRefs<"offerCard_LicenseProductFragment">;
    } | null;
    readonly name: string;
    readonly shortDescription: string | null;
    readonly tags: ReadonlyArray<{
      readonly data: {
        readonly name: string;
      };
      readonly id: string;
    }>;
  } | null;
  readonly id: string;
  readonly licenseAvailability: {
    readonly numAvailable?: number;
  };
  readonly setting: {
    readonly kind: CoachingSettingKind;
  };
  readonly " $fragmentType": "coachingOfferCard_CoachingOfferFragment";
};
export type coachingOfferCard_CoachingOfferFragment$key = {
  readonly " $data"?: coachingOfferCard_CoachingOfferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coachingOfferCard_CoachingOfferFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "coachingOfferCard_CoachingOfferFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachingOfferDescription",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AcademiesTagData",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LicenseProduct",
          "kind": "LinkedField",
          "name": "licenseProduct",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "offerCard_LicenseProductFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "setting",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachProfile",
      "kind": "LinkedField",
      "name": "associatedCoaches",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "coach",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "licenseAvailability",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numAvailable",
              "storageKey": null
            }
          ],
          "type": "LicenseAvailabilityFreeLicensesAvailable",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoachingOffer",
  "abstractKey": null
};
})();

(node as any).hash = "5a3958a6815c1e5e1648ddedbe7eb284";

export default node;
