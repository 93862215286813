export type AccountData = {
	id: string;
	extension: {
		licenseModelKind: string;
	};
}[];

export const getLicenseModelKindForAccount = (
	accountData: AccountData,
	currentAccountId?: string,
) => {
	if (accountData && currentAccountId) {
		const account = accountData.find((account) => account.id === currentAccountId);
		return account?.extension?.licenseModelKind;
	}
	return undefined;
};
