import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query root_GetRootQuery($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				root {
					...RootProgress_LearnOpportunityV2Fragment
					structureDefinition {
						title
						... on LearnOpportunityRootStructureDefinition {
							extension {
								... on PublishedRootExtensionImpl {
									reducedData {
										isIHK
									}
									unlockInfo {
										kind
									}
									trailer {
										url
									}
									licenseProduct {
										id
										title
									}
									learnGoals
									ihkState
								}
							}
							viewerTreeState {
								kind
								... on IsStartedViewerTreeState {
									headContentId
								}
							}
						}
					}
					typeDefinition {
						... on LearnOpportunityBranchTypeDefinition {
							children {
								typeDefinition {
									... on LearnOpportunityBranchTypeDefinition {
										children {
											id
										}
									}
								}
							}
						}
					}
					instructorsV2 {
						edges {
							__typename
						}
					}
					...branchNodesTab_LearnOpportunityV2Fragment
					...materialsTab_LearnOpportunityV2Fragment
				}
			}
		}
	}
`;
