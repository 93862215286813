import { graphql } from "babel-plugin-relay/macro";

export const PUBLISHED_LEARNABLE_FRAGMENT = graphql`
	fragment coachingLearnableCard_PublishedLearnableFragment on PublishedLearnable {
		... on PublishedGroupCoachingLearnable {
			kind
			id
			groupCoaching {
				id
				description {
					name
					description
					shortDescription
					tags {
						id
						data {
							name
						}
					}
					image {
						thumbnail
						url
					}
					licenseProduct {
						...offerCard_LicenseProductFragment
					}
				}
				setting {
					kind
				}
				associatedCoaches {
					coach {
						name
					}
				}
				licenseAvailability {
					... on LicenseAvailabilityFreeLicensesAvailable {
						numAvailable
					}
				}
			}
		}
		... on PublishedOneToOneCoachingLearnable {
			kind
			id
			oneToOneCoaching {
				id
				description {
					name
					description
					shortDescription
					tags {
						id
						data {
							name
						}
					}
					image {
						thumbnail
						url
					}
					licenseProduct {
						...offerCard_LicenseProductFragment
					}
				}
				setting {
					kind
				}
				associatedCoaches {
					coach {
						name
					}
				}
				licenseAvailability {
					... on LicenseAvailabilityFreeLicensesAvailable {
						numAvailable
					}
				}
			}
		}
	}
`;
