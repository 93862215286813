import { useRef } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { OverlayPanel } from "@components/overlay-panel";
import { HTMLMouseEvent } from "@customTypes/index";
import { useWindowSize } from "@hooks/use-window-size";
import { avatarWithProfileMenu_AuthViewerSchemaFragment$key } from "@relay/avatarWithProfileMenu_AuthViewerSchemaFragment.graphql";
import { Path } from "@router/paths";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./avatar-with-profile-menu.graphql";
import { AvatarWrapper, Wrapper } from "./avatar-with-profile-menu.styles";
import { AvatarWithProfileMenuProps } from "./avatar-with-profile-menu.types";
import { ProfileMenu } from "../profile-menu";
import { UserAvatar } from "../user-avatar";

export const AvatarWithProfileMenu = ({
	authViewerSchemaFragmentRef,
	children,
}: AvatarWithProfileMenuProps) => {
	const auth = useFragment<avatarWithProfileMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const navigate = useNavigate();

	const overlayPanelRef = useRef<OverlayPanel>(null);

	const { isMediumUp } = useWindowSize();

	const handleAvatarOnClick = (event: HTMLMouseEvent) => {
		overlayPanelRef.current?.toggle(event);
		!isMediumUp && navigate(Path.profileMenu.path);
	};

	const handleOnNavigated = (event?: HTMLMouseEvent) => {
		overlayPanelRef.current?.toggle(event);
	};

	const handleOnClose = () => {
		overlayPanelRef.current?.hide();
	};

	if (!auth.currentUser) return null;
	return (
		<Wrapper>
			{isMediumUp && (
				<OverlayPanel ref={overlayPanelRef}>
					<ProfileMenu onNavigated={handleOnNavigated} onClose={handleOnClose} />
				</OverlayPanel>
			)}
			<AvatarWrapper onClick={handleAvatarOnClick}>
				{children ?? <UserAvatar userFragmentRef={auth.currentUser.user} />}
			</AvatarWrapper>
		</Wrapper>
	);
};
