import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query licenseDetails_Query($licenseGroupingId: ID!) {
		...licenseDetails_LicenseGroupingFragment @arguments(licenseGroupingId: $licenseGroupingId)
		...licensesNewSingleUserAssignmentForm_QueryFragment
		...userRulesTab_UserQueryFragment

		AccountMemberManagement {
			UserInAccountGroupWrappers(
				includeOwner: true
				includeUser: true
				doCalcMemberCount: false
			) {
				count
				userInAccountGroupWrappers {
					__id
					edges {
						node {
							memberCount
							group {
								id
								name
								isBuiltIn
							}
						}
					}
				}
			}
		}
		Viewer {
			Auth {
				currentUser {
					accounts {
						id
						extension {
							... on AccountExtensionImpl {
								licenseModelKind
							}
						}
					}
				}
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment licenseDetails_LicenseGroupingFragment on Query
	@refetchable(queryName: "licenseDetails_RefetchQuery")
	@argumentDefinitions(licenseGroupingId: { type: "ID!" }) {
		LicenseGrouping {
			LicenseGrouping(id: $licenseGroupingId) {
				id
				usedLicenseAmount
				totalLicenseAmount
				kind
				... on LearnableLicenseGrouping {
					learnable {
						kind
						... on PublishedGroupCoachingLearnable {
							id
							groupCoaching {
								id
								description {
									name
									description
								}
							}
						}
						... on PublishedOneToOneCoachingLearnable {
							id
							oneToOneCoaching {
								id
								description {
									name
									description
								}
							}
						}
						... on PublishedCourseLearnable {
							id
							root {
								id
								description
								structureDefinition {
									title
								}
							}
						}
						... on PublishedMicroLearningLearnable {
							id
							root {
								id
								description
								structureDefinition {
									title
								}
							}
						}
					}
				}
				...licensesProductCard_QueryFragment
				assignmentRules {
					__typename
					...licensesAccountGroupAssignmentCard_QueryFragment
					...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment
					...userRulesTab_QueryFragment
					...groupRulesTab_QueryFragment
				}
			}
		}
	}
`;
