import { Icon } from "@components/icon";
import { error100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { Card, Connector, Wrapper } from "./unlock-wall-item.styles";

//TODO: add-translations
export const UnlockWallItem = () => {
	return (
		<Wrapper>
			<Connector />
			<Card>
				<Icon icon="removeBadge" sizeInRem={1.5} tkaColor={error100} />
				<TkaP2Span tkaColor={error100}>
					Um diesen Kurs abschließen zu können, wird eine&nbsp;
					<TkaP2Span tkaColor={error100} bold>
						Lizenz benötigt.
					</TkaP2Span>
				</TkaP2Span>
			</Card>
			<Connector />
		</Wrapper>
	);
};
