import { graphql } from "babel-plugin-relay/macro";

export const PUBLISHED_COURSE_LEARNABLE_FRAGMENT = graphql`
	fragment educationalOfferCard_PublishedCourseLearnableFragment on PublishedLearnable {
		... on PublishedCourseLearnable {
			id
			kind
			root {
				id
				description
				shortDescription
				image {
					url
				}
				typeDefinition {
					... on LearnAsyncContentTypeDefinition {
						extension {
							... on LearnContentExtensionImpl {
								isUnlockNecessaryToStart
							}
						}
					}
				}
				structureDefinition {
					... on LearnOpportunityRootStructureDefinition {
						progressPercentage
						title
						viewerTreeState {
							kind
							... on IsStartedViewerTreeState {
								startedAt
								headContentId
							}
						}
						extension {
							... on PublishedRootExtensionImpl {
								unlockInfo {
									kind
									... on UnlockInfoDemo {
										firstContentId
									}
								}
								diploma
								tags {
									id
									kind
									data {
										name
										isClickable
										isTopic
									}
								}
								licenseProduct {
									...offerCard_LicenseProductFragment
								}
								extension {
									... on PublishedConstellationRootExtensionExtension {
										isMicroLearning
										licenseAvailability {
											... on LicenseAvailabilityFreeLicensesAvailable {
												numAvailable
											}
										}
										contractPartner {
											id
											data {
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		... on PublishedMicroLearningLearnable {
			id
			kind
			root {
				id
				description
				shortDescription
				image {
					url
				}
				typeDefinition {
					... on LearnAsyncContentTypeDefinition {
						extension {
							... on LearnContentExtensionImpl {
								isUnlockNecessaryToStart
							}
						}
					}
				}
				structureDefinition {
					... on LearnOpportunityRootStructureDefinition {
						progressPercentage
						title
						viewerTreeState {
							kind
							... on IsStartedViewerTreeState {
								headContentId
								startedAt
							}
						}
						extension {
							... on PublishedRootExtensionImpl {
								unlockInfo {
									kind
									... on UnlockInfoDemo {
										firstContentId
									}
								}
								diploma
								tags {
									id
									kind
									data {
										name
										isClickable
										isTopic
									}
								}
								licenseProduct {
									...offerCard_LicenseProductFragment
								}
								extension {
									... on PublishedConstellationRootExtensionExtension {
										isMicroLearning
										licenseAvailability {
											... on LicenseAvailabilityFreeLicensesAvailable {
												numAvailable
											}
										}
										contractPartner {
											id
											data {
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
