import graphql from "babel-plugin-relay/macro";

export const EDIT_LICENSE_MODEL_MUTATION = graphql`
	mutation licenseSettings_EditLicenseModelMutation($input: EditLicenseModelInput!) {
		LicenseModel {
			editLicenseModel(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment licenseSettings_QueryFragment on Query
	@refetchable(queryName: "licenseSettings_QueryFragmentRefetch") {
		Viewer {
			Auth {
				currentUser {
					accounts {
						id
						extension {
							... on AccountExtensionImpl {
								licenseModelKind
							}
						}
					}
				}
			}
		}
	}
`;
