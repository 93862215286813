import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query coachingDetails_GetCoachingQuery($id: ID!) {
		node(id: $id) {
			... on CoachingOffer {
				description {
					name
					description
					contents
					modules
					image {
						url
					}
				}
				associatedCoaches {
					id
					...coachCard_UserFragment
				}
				licenseAvailability {
					... on LicenseAvailabilityFreeLicensesAvailable {
						numAvailable
					}
				}
				cartClickout {
					relativeLink
					netPrice
				}
			}
		}
	}
`;

export const USER_QUERY = graphql`
	query coachingDetails_UserQuery {
		AccountBaseData {
			AccountBaseData {
				... on PrivateBaseData {
					countryCode
				}
				... on BusinessBaseData {
					countryCode
				}
			}
		}
	}
`;
