/**
 * @generated SignedSource<<3f7fd0e21f3fa6a1deb9e3d7e50bf2fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CoachingSettingKind = "Group" | "OneToOne";
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type coachingLearnableCard_PublishedLearnableFragment$data = {
  readonly groupCoaching?: {
    readonly associatedCoaches: ReadonlyArray<{
      readonly coach: {
        readonly name: string;
      } | null;
    }>;
    readonly description: {
      readonly description: string | null;
      readonly image: {
        readonly thumbnail: string | null;
        readonly url: string | null;
      } | null;
      readonly licenseProduct: {
        readonly " $fragmentSpreads": FragmentRefs<"offerCard_LicenseProductFragment">;
      } | null;
      readonly name: string;
      readonly shortDescription: string | null;
      readonly tags: ReadonlyArray<{
        readonly data: {
          readonly name: string;
        };
        readonly id: string;
      }>;
    } | null;
    readonly id: string;
    readonly licenseAvailability: {
      readonly numAvailable?: number;
    };
    readonly setting: {
      readonly kind: CoachingSettingKind;
    };
  } | null;
  readonly id?: string;
  readonly kind?: LearnableKind;
  readonly oneToOneCoaching?: {
    readonly associatedCoaches: ReadonlyArray<{
      readonly coach: {
        readonly name: string;
      } | null;
    }>;
    readonly description: {
      readonly description: string | null;
      readonly image: {
        readonly thumbnail: string | null;
        readonly url: string | null;
      } | null;
      readonly licenseProduct: {
        readonly " $fragmentSpreads": FragmentRefs<"offerCard_LicenseProductFragment">;
      } | null;
      readonly name: string;
      readonly shortDescription: string | null;
      readonly tags: ReadonlyArray<{
        readonly data: {
          readonly name: string;
        };
        readonly id: string;
      }>;
    } | null;
    readonly id: string;
    readonly licenseAvailability: {
      readonly numAvailable?: number;
    };
    readonly setting: {
      readonly kind: CoachingSettingKind;
    };
  } | null;
  readonly " $fragmentType": "coachingLearnableCard_PublishedLearnableFragment";
};
export type coachingLearnableCard_PublishedLearnableFragment$key = {
  readonly " $data"?: coachingLearnableCard_PublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coachingLearnableCard_PublishedLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachingOfferDescription",
    "kind": "LinkedField",
    "name": "description",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortDescription",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "image",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseProduct",
        "kind": "LinkedField",
        "name": "licenseProduct",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "offerCard_LicenseProductFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "setting",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachProfile",
    "kind": "LinkedField",
    "name": "associatedCoaches",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "coach",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "licenseAvailability",
    "plural": false,
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numAvailable",
            "storageKey": null
          }
        ],
        "type": "LicenseAvailabilityFreeLicensesAvailable",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "coachingLearnableCard_PublishedLearnableFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CoachingOffer",
          "kind": "LinkedField",
          "name": "groupCoaching",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PublishedGroupCoachingLearnable",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CoachingOffer",
          "kind": "LinkedField",
          "name": "oneToOneCoaching",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PublishedOneToOneCoachingLearnable",
      "abstractKey": null
    }
  ],
  "type": "PublishedLearnable",
  "abstractKey": "__isPublishedLearnable"
};
})();

(node as any).hash = "f87c67f8663672f37fc9ccaf17c26e42";

export default node;
