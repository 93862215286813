import tw from "twin.macro";

import { ReactComponent as LogoSquareSVG } from "@assets/logo_square.svg";

export const Wrapper = tw.div`
	xl:h-full
	flex
	flex-row
	xl:flex-col
	xl:bg-brand-main-100
	gap-40
	items-center
	px-24
	py-16
	xl:px-16
	xl:py-24
`;

export const CenterWrapper = tw.div`
	flex-1
	flex
	flex-row
	xl:flex-col
	justify-between
	xl:justify-center
	items-center
`;

export const StyledLogo = tw(LogoSquareSVG)`
    w-[2.125rem]
	h-[2.125rem]
	hidden
	xl:block
	hover:cursor-pointer
`;

export const ShowXLUp = tw.div`hidden xl:block`;
export const HideXLUp = tw.div`block xl:hidden`;
