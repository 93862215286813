import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { OfferCard } from "@components/offer-card";
import { coachingLearnableCard_PublishedLearnableFragment$key } from "@relay/coachingLearnableCard_PublishedLearnableFragment.graphql";
import { Path } from "@router/paths";
import { stripHtml } from "@utils/strip-html";
import { PUBLISHED_LEARNABLE_FRAGMENT } from "./coaching-learnable-card.graphql";
import { CoachingLearnableCardProps } from "./coaching-learnable-card.types";

export const CoachingLearnableCard = ({
	coachingLearnableFragmentRef,
	isDashboardCard = false,
}: CoachingLearnableCardProps) => {
	const learnable = useFragment<coachingLearnableCard_PublishedLearnableFragment$key>(
		PUBLISHED_LEARNABLE_FRAGMENT,
		coachingLearnableFragmentRef,
	);

	const coaching = learnable.groupCoaching ?? learnable.oneToOneCoaching;

	const navigate = useNavigate();
	const handleOnClick = () => {
		if (!coaching?.id) return;
		navigate(Path.coaching.withId(coaching.id).path);
	};
	const shortDescription = useMemo(
		() => stripHtml(coaching?.description?.shortDescription ?? ""),
		[coaching?.description?.shortDescription],
	);
	const tags = useMemo(
		() => coaching?.description?.tags?.map((t) => t.data.name),
		[coaching?.description?.tags],
	);
	if (learnable.kind !== "GroupCoaching" && learnable.kind !== "OneToOneCoaching")
		return <React.Fragment />;

	return (
		<OfferCard
			src={coaching?.description?.image?.url ?? ""}
			licenseProductFragmentRef={coaching?.description?.licenseProduct}
			title={coaching?.description?.name ?? ""}
			shortDescription={shortDescription}
			tags={tags}
			availableLicenseCount={coaching?.licenseAvailability.numAvailable ?? 0}
			onClick={handleOnClick}
			showPrice={!isDashboardCard}
		/>
	);
};
