import { graphql } from "babel-plugin-relay/macro";

export const LICENSE_PRODUCT_FRAGMENT = graphql`
	fragment offerCard_LicenseProductFragment on LicenseProduct {
		deGrossPrice
		atGrossPrice
		chGrossPrice
		isTaxFree
		netPrice
	}
`;

export const QUERY = graphql`
	query offerCard_Query {
		AccountBaseData {
			AccountBaseData {
				... on PrivateBaseData {
					countryCode
				}
				... on BusinessBaseData {
					countryCode
				}
			}
		}
	}
`;
